import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "swiper/css/swiper.css";
import App from './app/App';
import reportWebVitals from './app/reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import TagManager from 'react-gtm-module'
import {StoreProvider} from 'easy-peasy';
import store from './store/store';

const tagManagerArgs = {
    gtmId: 'GTM-TMVZ4CC'
}

TagManager.initialize(tagManagerArgs)

ReactDOM.render(
    <BrowserRouter>
        <StoreProvider store={store}>
            <App/>
        </StoreProvider>
    </BrowserRouter>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
