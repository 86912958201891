import { createStore, persist } from 'easy-peasy';
import { homepageModel } from "../models/homepage";
import {currentUserModel} from "../models/me";

const models = {
    homepage: homepageModel,
    currentUser: persist(currentUserModel),
}

const store = createStore(models);

export default store;
