import {lazy, Suspense, useEffect} from "react";
import "../components/components.scss";
import {Route, Switch, withRouter} from "react-router-dom";
import LoadingSpinner from "../components/elements/LoadingSpinner/LoadingSpinner";
import PublicRoute from "../components/Routes/PublicRoute/PublicRoute";
import PrivateRoute from "../components/Routes/PrivateRoute/PrivateRoute";
import branch from 'branch-sdk'
import axios from 'axios';

let canonical = document.querySelector('link[rel="canonical"]')
let ogUrl = document.head.querySelector("[property~='og:url'][content]")
let siteName = document.head.querySelector("[property~='og:site_name'][content]")
canonical.setAttribute('href', window.location.href)
ogUrl.setAttribute('content', window.location.href)
siteName.setAttribute('content', window.location.href)

function App({history}) {
    const AccountDetails = lazy(() => import('../components/User/AccountDetails/AccountDetails'))
    const AffiliateAccountDetails = lazy(() => import('../components/User/AffiliateAccountDetails/AffiliateAccountDetails'))

    useEffect(() => {
        let isMount = true
        if (isMount) {
            const key = process.env.REACT_APP_BRANCHIO_KEY
            const getRedirect = (type, id) => {
                axios.get(`${process.env.REACT_APP_API_URL}api/v1/${type}s/${id}`).then(res => {
                    res && res.data && history.push({pathname: `/${res.data.url}`})
                })
            }
            key && branch.init(key, {}, function (err, data) {
                const params = data && data.data_parsed
                if (params && params["$canonical_identifier"]) {
                    const canonicalIdentifier = params["$canonical_identifier"]
                    const splitedParams = canonicalIdentifier.split('/')
                    const type = splitedParams[0]
                    const id = splitedParams[splitedParams.length - 1]
                    if (type && id) {
                        getRedirect(type, id)
                    }
                }
            })
        }
        return() => {
            isMount = false
        }
    }, [history])
    return (
        <>
            <Suspense fallback={<></>}>
                <Switch>
                    <PublicRoute path="/adventure-guides/:slug+"
                                 component={lazy(() => import('../components/GuidePage/GuidePage'))}/>
                    <PublicRoute path="/guide-reviews"
                                 component={lazy(() => import('../components/GuidePage/AllGuideReviews/AllGuideReviews'))}/>
                    <PublicRoute path="/adventures/:slug+"
                                 component={lazy(() => import('../components/AdventureDetails/AdventureDetails'))}/>
                    <PublicRoute path="/review-adventure/:adventure_id"
                                 component={lazy(() => import('../components/AdventureDetails/AdventureTabs/ReviewsSection/ReviewAdventure/ReviewAdventure'))}/>
                    <PublicRoute path="/adventure-partners/:slug+"
                                 component={lazy(() => import('../components/PartnerPage/PartnerPage'))}/>
                    <PublicRoute exact path="/" component={lazy(() => import('../components/Homepage/NewHomepage'))}/>
                    <PublicRoute path="/Search" component={lazy(() => import('../components/Search/SearchPage'))}/>
                    <PublicRoute exact path="/login-choose" restricted={true}
                                 component={lazy(() => import('../components/Menu/LoginChoose/LoginChoose'))}/>
                    <PublicRoute path="/login-choose/login-page" restricted={true}
                                 component={lazy(() => import('../components/Menu/LoginChoose/LoginPage/LoginPage'))}/>
                    <PublicRoute path="/login-choose/user-registration" restricted={true}
                                 component={lazy(() => import('../components/Menu/RegisterPage/RegisterPage'))}/>
                    <PublicRoute path="/login-choose/referral-code" restricted={true}
                                 component={lazy(() => import('../components/Menu/RegisterPage/ReferralCode/ReferralCode'))}/>
                    <PublicRoute path="/login-choose/guide-registration" restricted={true}
                                 component={lazy(() => import('../components/Menu/RegisterPage/GuideRegistration/GuideRegistration'))}/>
                    <PublicRoute path="/adventure-duration/:id"
                                 component={lazy(() => import('../components/AdventureDetails/AdventureDuration/AdventureDuration'))}/>
                    <PublicRoute exact path="/book-adventure"
                                 component={lazy(() => import('../components/AdventureDetails/AdventureBook/AdventureBook'))}/>
                    <PrivateRoute
                        path="/book-adventure/payment-method"
                        type="both"
                        component={lazy(() => import('../components/AdventureDetails/AdventureBook/PaymentMethod/PaymentMethod'))}
                    />
                    <PublicRoute path="/book-adventure/payment-user"
                                 component={lazy(() => import('../components/AdventureDetails/AdventureBook/PaymentUser/PaymentUser'))}/>
                    <PrivateRoute
                        path="/book-adventure/booking-success"
                        component={lazy(() => import('../components/AdventureDetails/AdventureBook/BookingSuccess/BookingSuccess'))}
                    />
                    <PrivateRoute exact path="/my-adventures" type="both"
                                  component={lazy(() => import('../components/Menu/MyAdventures/MyAdventures'))}/>
                    <PrivateRoute
                        exact
                        path="/my-adventures/:id/manage-friends"
                        type="both"
                        component={lazy(() => import('../components/Menu/MyAdventures/ManageFriends/ManageFriends'))}
                    />
                    <PrivateRoute path="/my-adventures/view-receipt" type="both"
                                  component={lazy(() => import('../components/Menu/MyAdventures/ViewReceipt/ViewReceipt'))}/>
                    <PrivateRoute
                        exact
                        path="/my-adventures/:id/cancel-booking"
                        component={lazy(() => import('../components/Menu/MyAdventures/CancelBooking/CancelBooking'))}
                    />
                    <PrivateRoute
                        path="/my-adventures/:id/cancel-booking/reason"
                        component={lazy(() => import('../components/Menu/MyAdventures/CancelBooking/CancelBookingReason/CancelBookingReason'))}
                    />
                    <PrivateRoute
                        exact
                        path="/conversation-screen"
                        type="both"
                        component={lazy(() => import('../components/Menu/ConversationScreen/ConversationScreen'))}
                    />
                    <PrivateRoute
                        path="/conversation-screen/message-screen"
                        type="both"
                        component={lazy(() => import('../components/Menu/ConversationScreen/MessageScreen/MessageScreen'))}
                    />
                    <PrivateRoute exact path="/following-page"
                                  component={lazy(() => import('../components/Menu/FollowingPage/FollowingPage'))}/>
                    <PrivateRoute
                        path="/following-page/guides-you-follow"
                        component={lazy(() => import('../components/Menu/FollowingPage/FollowingGuides/FollowingGuides'))}
                    />
                    <PrivateRoute
                        path="/following-page/adventures-you-follow"
                        component={lazy(() => import('../components/Menu/FollowingPage/FollowingAdventures/FollowingAdventures'))}
                    />
                    <PrivateRoute
                        path="/following-page/partners-you-follow"
                        component={lazy(() => import('../components/Menu/FollowingPage/FollowingPartners/FollowingPartners'))}
                    />
                    <PrivateRoute
                        path="/my-adventures/:id/manage-friends/add-friends"
                        component={lazy(() => import('../components/Menu/MyAdventures/ManageFriends/AddFriends/AddFriends'))}
                    />
                    <PrivateRoute exact
                                  path="/account-details"
                                  type="both"
                                  component={AccountDetails}
                    />
                    <PrivateRoute
                        path="/account-details/change-password"
                        type="both"
                        component={lazy(() => import('../components/User/AccountDetails/ProfileInfo/ChangePassword/ChangePassword'))}
                    />
                    <PrivateRoute path="/interests-page" type="both"
                                  component={lazy(() => import('../components/Menu/InterestsPage/InterestsPage'))}/>
                    <PrivateRoute exact path="/adventure-credits"
                                  component={lazy(() => import('../components/Menu/AdventureCredits/AdventureCredits'))}/>
                    <PrivateRoute exact path="/adventure-credits/buy-adventure-credits"
                                  component={lazy(() => import('../components/Menu/AdventureCredits/BuyAdventureCredits/BuyAdventureCredits'))}/>
                    <PrivateRoute path="/adventure-credits/buy-adventure-credits/purchase-success"
                                  component={lazy(() => import('../components/Menu/AdventureCredits/BuyAdventureCredits/PurchaseSuccess/PurchaseSuccess'))}/>
                    <Route path="/registration-approval"
                           component={lazy(() => import('../components/Menu/RegisterPage/GuideRegistration/RegistrationApproval/RegistrationApproval'))}/>
                    <PrivateRoute path="/create-adventure-page" type="guide"
                                  component={lazy(() => import('../components/MainGuideScreen/CreateAdventurePage/CreateAdventurePage'))}/>
                    <PrivateRoute path="/update-adventure-page/:id" type="guide"
                                  component={lazy(() => import('../components/MainGuideScreen/CreateAdventurePage/CreateAdventurePage'))}/>
                    <PrivateRoute path="/adventures" type="guide"
                                  component={lazy(() => import('../components/MainGuideScreen/GuideAdventures/GuideAdventures'))}/>
                    <Route path="/adventure-blog/:slug/:id"
                           component={lazy(() => import('../components/BlogPage/BlogPage'))}/>
                    <Route path="/all-guided-activities"
                           component={lazy(() => import('../components/Explore/SearchBar/AllActivities/AllActivities'))}/>
                    <Route path="/all-adventure-guides"
                           component={lazy(() => import('../components/Explore/SearchBar/AllGuides/AllGuides'))}/>
                    <Route path="/all-regions"
                           component={lazy(() => import('../components/Explore/SearchBar/AllRegions/AllRegions'))}/>
                    <Route path="/all-epic-adventures"
                           component={lazy(() => import('../components/Explore/SearchBar/AllCategories/AllCategories'))}/>
                    <Route path="/all-partners"
                           component={lazy(() => import('../components/Explore/SearchBar/AllPartners/AllPartners'))}/>
                    <Route path="/guided-activities/:slug+"
                           component={lazy(() => import('../components/Activity/Activity'))}/>
                    <Route path="/guided-adventures/:slug+"
                           component={lazy(() => import('../components/Region/Region'))}/>
                    <Route path="/epic-adventures/:slug+"
                           component={lazy(() => import('../components/Category/Category'))}/>
                    <Route path="/adventure-blog"
                           component={lazy(() => import('../components/Explore/LatestStories/AllPosts/AllPosts'))}/>
                    <Route exact path="/adventure-availability/:id"
                           component={lazy(() => import('../components/AdventureDetails/AffiliateAdventureAvailability/AffiliateAdventureAvailability'))}/>
                    <Route path="/adventure-availability/:id/select-user"
                           component={lazy(() => import('../components/AdventureDetails/AffiliateAdventureAvailability/SelectUserPage/SelectUserPage'))}/>
                    <Route path="/clients-page"
                           component={lazy(() => import('../components/AdventureDetails/AffiliateAdventureAvailability/SelectUserPage/SelectUserPage'))}/>
                    <Route path="/all-bookings"
                           component={lazy(() => import('../components/Menu/MyAdventures/AllBookings/AllBookings'))}/>
                    <PrivateRoute affiliate={true} type="both" path="/users-page"
                                  component={lazy(() => import('../components/Menu/UsersPage/UsersPage'))}/>
                    <PrivateRoute exact
                                  path="/affiliate-account-details"
                                  type="both"
                                  affiliate={true}
                                  component={AffiliateAccountDetails}
                    />
                    <Route path="/shared-adventure/:adventure_id/:user_name/from/:company_name/:token"
                           component={lazy(() => import('../components/AdventureDetails/ViewThisAdventure/ViewThisAdventure'))}/>
                    <Route path="/booked-adventure/:adventure_id/:user_name/from/:company_name/:token"
                           component={lazy(() => import('../components/AdventureDetails/ViewThisAdventure/ViewThisAdventure'))}/>
                    <PrivateRoute
                        path="/book-adventure/book-on-behalf-success"
                        component={lazy(() => import('../components/AdventureDetails/AdventureBook/AffiliateBookingSuccess/AffiliateBookingSuccess'))}
                    />
                    <PrivateRoute
                        path="/book-adventure/share-success"
                        component={lazy(() => import('../components/AdventureDetails/AdventureBook/AffiliateShareSuccess/AffiliateShareSuccess'))}
                    />
                    <Route path="/create-password"
                           component={lazy(() => import('../components/AdventureDetails/ViewThisAdventure/CreatePasswordPage/CreatePasswordPage'))}/>
                    <Route path="/update-password/:token"
                           component={lazy(() => import('../components/Menu/LoginChoose/UpdatePassword/UpdatePassword'))}/>
                    <PrivateRoute exact path="/guide-public-profile" type="guide"
                                  component={lazy(() => import('../components/MainGuideScreen/GuidePublicProfile/GuidePublicProfile'))}/>
                    <PrivateRoute path="/guide-public-profile/guide-reviews" type="guide"
                                  component={lazy(() => import('../components/GuidePage/AllGuideReviews/AllGuideReviews'))}/>
                    <PrivateRoute path="/manage-availability" type="guide"
                                  component={lazy(() => import('../components/MainGuideScreen/ManageAvailability/ManageAvailability'))}/>
                    <PrivateRoute path="/guide-page-calendar" type="guide"
                                  component={lazy(() => import('../components/MainGuideScreen/GuidePageCalendar/GuidePageCalendar'))}/>
                    <PrivateRoute exact path="/my-adventures/:id/manage-guest-waivers" type="guide"
                                  component={lazy(() => import('../components/Menu/MyAdventures/ManageGuestWaivers/ManageGuestWaivers'))}/>
                    <PrivateRoute exact path="/my-adventures/:id/manage-guest-waivers/:guest_id" type="guide"
                                  component={lazy(() => import('../components/Menu/MyAdventures/ManageGuestWaivers/GuestWaivers/GuestWaivers'))}/>
                    <PrivateRoute exact path="/guide-account-details" type="guide"
                                  component={lazy(() => import('../components/User/GuideAccountDetails/GuideAccountDetails'))}/>
                    <PrivateRoute
                        path="/book-adventure/select-payment-method"
                        type="both"
                        affiliate={true}
                        component={lazy(() => import('../components/AdventureDetails/AdventureBook/PaymentMethodAffiliate/PaymentMethodAffiliate'))}
                    />
                    <PrivateRoute exact path="/my-adventures/:id/tip-guide/:guide_name"
                                  component={lazy(() => import('../components/Menu/MyAdventures/TipGuide/TipGuide'))}/>
                    <Route component={lazy(() => import('../components/NotFoundComponent/NotFoundComponent'))}/>
                </Switch>
            </Suspense>
            <LoadingSpinner/>
        </>
    );
}

export default withRouter(App);
