import BackendAPI from "./backendApi";

const url = `${process.env.REACT_APP_API_URL}api/v1/users`;

export default class UsersService {
  static createGuide(data) {
    return BackendAPI.post(url, data);
  }

  static createUser(data) {
    return BackendAPI.post(url, data);
  }

  static login(data) {
    return BackendAPI.post(`${url}/login`, data);
  }

  static getTrendingUsers() {
    return BackendAPI.get(`${url}/trending`, {
      params: {
        hideLoader: true
      }
    });
  }

  static getUser(id) {
    return BackendAPI.get(`${url}/${id}`);
  }

  static getGuide(params) {
    if (params.param_id) {
      return BackendAPI.get(`${url}/${params.param_id}`)
    } else {
      return BackendAPI.get(`${url}`, { params: params })
    }
  }

  static logOutUser() {
    return BackendAPI.get(`${url}/logout`);
  }

  static getCurrentUser(source) {
    return BackendAPI.get(`${url}/me`, {
      params: {
        hideLoader: true
      },
      cancelToken: source ? source.token: false
    });
  }

  static updateCurrentUser(data) {
    return BackendAPI.put(`${url}/me`, data);
  }

  static updateCurrentUserPassword(data) {
    return BackendAPI.put(`${url}/me/password`, data);
  }

  static saveUserCard(data) {
    return BackendAPI.post(`${url}/me/cards`, data);
  }

  static getCurrentUserCards() {
    return BackendAPI.get(`${url}/me/cards`);
  }

  static getCurrentUserAccounts() {
    return BackendAPI.get(`${url}/me/banks`);
  }

  static saveUserAccount(data) {
    return BackendAPI.post(`${url}/me/banks`, data);
  }

  static getRelatedInterests() {
    return BackendAPI.get(`${url}/me/interests`);
  }

  static getRelatedActivityInterests() {
    return BackendAPI.get(`${url}/me/interests/activities`);
  }

  static addRelatedActivityInterest(data) {
    return BackendAPI.post(`${url}/me/interests/activities`, data);
  }

  static updateRelatedActivityInterest(data) {
    return BackendAPI.put(`${url}/me/interests/activities`, data, {
      params: {
        hideLoader: true
      }
    });
  }

  static updateRelatedGuideInterest(data) {
    return BackendAPI.put(`${url}/me/interests/guides`, data, {
      params: {
        hideLoader: true
      }
    });
  }

  static updateRelatedPartnerInterest(data) {
    return BackendAPI.put(`${url}/me/interests/partners`, data, {
      params: {
        hideLoader: true
      }
    });
  }

  static updateRelatedRegionInterest(data) {
    return BackendAPI.put(`${url}/me/interests/regions`, data, {
      params: {
        hideLoader: true
      }
    });
  }

  static getGuideAdventures(source) {
    return BackendAPI.get(`${url}/me/adventures`, { cancelToken: source ? source.token: false });
  }

  static addUserProfileImage(data) {
    return BackendAPI.post(`${url}/me/images/profile`, data);
  }

  static getCurrentUserBookings(dates) {
    return BackendAPI.get(`${url}/me/bookings`, { params: { dates } });
  }

  static getCurrentUserBookingsForCertainAdventure(id, dates) {
    return BackendAPI.get(`${url}/me/adventurebookings/${id}`, {
      params: { dates, hideLoader: true }
    });
  }

  static getGuideAvailabilitiesAndBookings(dates, hideLoader) {
    return BackendAPI.get(`${url}/me/availabilities`, { params: { 
      dates,
      hideLoader: hideLoader ? hideLoader: false
    } });
  }

  static resetUserPassword(email) {
    return BackendAPI.post(
      `${process.env.REACT_APP_API_URL}api/v1/password/reset`, null, { params: { email } }
    );
  }

  static getMyFollows() {
    return BackendAPI.get(`${url}/me/following`);
  }

  static followGuide(id) {
    return BackendAPI.post(`${url}/${id}/follow`);
  }

  static unfollowGuide(id) {
    return BackendAPI.delete(`${url}/${id}/follow`);
  }

  static validateReferralCode(data) {
    return BackendAPI.post(`${process.env.REACT_APP_API_URL}api/v1/referral/validate`, data);
  }

  static getGuideDashboardInfo() {
    return BackendAPI.get(`${url}/me/dashboard`);
  }

  static storeManyAvailabilities(data) {
    return BackendAPI.post(`${url}/me/availabilities-by-variant-ids`, data);
  }

  static createPassword(data) {
    return BackendAPI.put(`${url}/password`, data);
  }

  static checkToken(token) {
    return BackendAPI.get(`${process.env.REACT_APP_API_URL}api/v1/token?token=${token}`);
  }

  static getAllGuides(params) {
    return BackendAPI.get(`${process.env.REACT_APP_API_URL}api/v1/guides`, { params: params });
  }

  static userPasswordReset(params) {
    return BackendAPI.post(`${process.env.REACT_APP_API_URL}api/v1/password/reset`, params);
  }
}
