import React, { useState, useEffect } from 'react';
import { css } from '@emotion/core';
import PulseLoader from 'react-spinners/PulseLoader';
import LoaderService from './../../../services/loaderService';
import { isMobile } from "react-device-detect";
const spinnerSize = isMobile ? 8 : 12;
const override = css`
    display: block;
    margin: 0 auto;
    border-color: #8FC0C3 #8FC0C3 transparent;
    top: calc(50% - ${spinnerSize/2}px);
    left: calc(50% - ${spinnerSize/2}px);
    position: fixed;
    transform: translate(-50%, -50%);
`;
const wrapCss = {
  width: '100%',
  height: '100%',
  position: 'fixed',
  left: '0',
  top: '0',
  background: 'rgba(0, 0, 0, .4)',
  zIndex: '1151'
};
const LoadingSpinner = () => {
  const [loading, setLoading] = useState(0)

  useEffect(() => {
    let isMount = true
    if (isMount) {
      LoaderService.instance.loader.subscribe((response) => {
        setLoading(response)
      })
    }
    return() => {
      isMount = false
    }
  }, [])
  return (
    <div className='sweet-loading' style={loading ? wrapCss : {}}>
      <PulseLoader
        css={override}
        sizeUnit={"px"}
        size={spinnerSize}
        color={'#8FC0C3'}
        loading={loading !== 0}
      />
    </div>
  )
}
export default React.memo(LoadingSpinner)