import axios from "axios";
import LoaderService from "./loaderService";

let pendingCount = 0;
let isLoader = true;

const manageResponse = () => {
  if (isLoader && pendingCount !== 0) {
    pendingCount--;
  }
  isLoader = true;
  localStorage.setItem("pendingCount", pendingCount);
  LoaderService.instance.showLoader(pendingCount);
};

const BackendAPI = axios.create({ baseURL: process.env.REACT_APP_API_HOST });

BackendAPI.interceptors.response.use(
  response => {
    manageResponse();
    return response;
  },
  error => {
    manageResponse();
    if(error?.response?.status === 404) {
      window.location.href="/not-found"
    }
    // if (error && error.response && error.response.status === 403) {
    //   localStorage.removeItem(process.env.REACT_APP_TOKEN_KEY);
    // }
    return Promise.reject(error);
  }
);

BackendAPI.interceptors.request.use(config => {
  if (config.params && config.params.hideLoader) {
    isLoader = false;
    delete config.params.hideLoader;
  } else {
    pendingCount++;
    LoaderService.instance.showLoader(pendingCount);
  }
  if (localStorage.getItem(process.env.REACT_APP_TOKEN_KEY)) {
    localStorage.setItem("pendingCount", pendingCount);
    config.headers.Authorization = `Bearer ${localStorage.getItem(
      process.env.REACT_APP_TOKEN_KEY
    )}`;
  }
  return config;
});

BackendAPI.defaults.headers.get["Content-Type"] = "application/json";
BackendAPI.defaults.headers.post["Content-Type"] = "application/json";
BackendAPI.defaults.headers.get["Access-Control-Allow-Origin"] = "*";
BackendAPI.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

export default BackendAPI;
