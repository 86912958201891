import {action, thunk} from "easy-peasy";
import axios from "axios";

export const homepageModel = {
    data: {},
    status: null,
    addData: action((state, payload) => {
        state.data = {...payload};
    }),
    getHomepage: thunk(async (actions, payload, { getStoreState }) => {
        const homepageData = getStoreState().homepage;
        if(typeof homepageData.data.adventures === 'undefined') {
            const { data } = await axios.get(`${process.env.REACT_APP_API_URL}api/v1/homepage`);
            actions.addData(data);
        }
    }),
}
