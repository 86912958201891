import {action, thunk} from "easy-peasy";
import UsersService from "../services/usersService";

export const currentUserModel = {
    data: {},
    status: null,
    addData: action((state, payload) => {
        state.data = {...payload};
    }),
    getCurrentUser: thunk(async (actions, payload, {getStoreState}) => {
        const currentUserData = getStoreState().currentUser;
        if (!currentUserData.data.id) {
            const {data} = await UsersService.getCurrentUser();
            actions.addData(data);
        }
    }),
}
